import Eddress from "../../../../model/point-of-sale/eddress/eddress.model";

export const parseToEddress = (pointOfSaleAddress, storeCountry) => {
    let operationUid = "";

    if (storeCountry === "BO") {
        operationUid = "ffff"
    } else if (storeCountry === "PY") {
        operationUid = "kompremos-py"
    }

    return new Eddress({
        locality: {
            coordinates: {
                lat: pointOfSaleAddress.lat,
                lon: pointOfSaleAddress.lng,
                empty: false,
            },
        },
        appName: "kompremos",
        operationUid: operationUid

    });
};

export default {
    parseToEddress,
};
