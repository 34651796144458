import eddressCustomer from "./parsers/eddress/parse-to-eddress";

const obtainEddressData =
    ({ client, authHeader }) =>
    async (addressLocation, country, accessToken) => {
        const baseUrl = window._env_.REACT_APP_EDDRESS_ADDRESS;
        if (!baseUrl) {
            throw new Error("La URL base para la API no está configurada");
        }
        const parsedAddres = eddressCustomer.parseToEddress(addressLocation, country);
        try {
            const response = await client.post(

                `${baseUrl}/api/market/web/public/`,
                parsedAddres,
                {
                    headers: authHeader(accessToken),
                }
            );


            const { status, data } = response;
            if (status !== 200) {
                throw new Error(`Error en la solicitud: Código de estado ${status}`);
            }

            return data;
        } catch (error) {
            console.error(">>>> Error en la solicitud:", error.message || error);
            throw new Error("Hubo un problema al agregar la dirección");
        }
    };

export default (client, authHeader) =>
    class EddressService {
        obtainEddressData = obtainEddressData({ client, authHeader });
    };
