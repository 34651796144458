
const saveAddress =
  ({ client, authHeader }) =>
  async ( commerceUid, addressData ,accessToken ) => {
    const token = window._env_.REACT_APP_EDDRESS_TOKEN
    const baseUrl = window._env_.REACT_APP_EDDRESS_ADDRESS
    const recepcionistUid = window._env_.REACT_APP_RECEPCIONIST_UID


    const response = await client.post(`${baseUrl}/api/market/web/users/${recepcionistUid}/addresses`, addressData,{
      headers: authHeader(token),
    });

    const data = response.data;

    if (data.message !== "valid") {
      throw new Error("Error al agregar la direccion");
    }
    return data;
  };

export default (client, authHeader) =>
  class AddressService {
    saveAddress = saveAddress({ client, authHeader });
  };
