import employeeParser from "./parsers/employee/parse-to-employee";
// import employeesParser from "./parsers/parse-to-employees";

const ENDPOINT = "employees";

const getAll =
  ({ client, authHeader }) =>
  async ({accessToken,storeCountry}) => {
    // return new Promise((resolve, reject) => {
    //   const parsedData = _fakeEmployees.map(employeeParser.parseToEmployee);
    //   const foundEmployees = parsedData.filter(
    //     (employee) =>
    //       employee.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       employee.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       employee.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       employee.nit.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       employee.socialReason
    //         .toLowerCase()
    //         .includes(searchQuery.toLowerCase())
    //   );
    //   resolve(foundEmployees);
    //   reject(new Error("Error"));
    // });

    const response = await client.get(
      `/${ENDPOINT}/${storeCountry}`,
      {
        headers: authHeader(accessToken),
      }
    );

    const employees = response.data.map(employeeParser.parseToEmployee);

    return employees;
  };


export default (client, authHeader) =>
  class EmployeeService {
    getAll = getAll({ client, authHeader });
  };
