class EddressOperation {
    constructor({ locality, appName, operationUid }) {
        this.locality = {
            coordinates: {
                lat: locality?.coordinates?.lat || null,
                lon: locality?.coordinates?.lon || null
            }
        };
        this.appName = appName || null;
        this.operationUid = operationUid || null;
    }
    
}

export default EddressOperation;

