import client from "./lib/request-manager";
import authHeader from "./lib/auth-header";

import StoreServiceFactory from "./store.service";
import ProductServiceFactory from "./product.service";
import InventoryServiceFactory from "./inventory.service";
import CustomerServiceFactory from "./customer.service";
import OrderServiceFactory from "./order.service";
import InvoiceServiceFactory from "./invoice.service";
import EmployeeServiceFactory from "./employee.service";
import AddressServiceFactory from "./address.service";
import EddressServiceFactory from "./eddress.service";


const StoreService = StoreServiceFactory(client, authHeader);
const ProductService = ProductServiceFactory(client, authHeader);
const InventoryService = InventoryServiceFactory(client, authHeader);
const CustomerService = CustomerServiceFactory(client, authHeader);
const OrderService = OrderServiceFactory(client, authHeader);
const InvoiceService = InvoiceServiceFactory(client, authHeader);
const EmployeeService = EmployeeServiceFactory(client, authHeader);
const AddressService = AddressServiceFactory(client,authHeader)
const EddressService = EddressServiceFactory(client,authHeader)



export default {
  storePointOfSaleService: new StoreService(),
  productPointOfSaleService: new ProductService(),
  inventoryPointOfSaleService: new InventoryService(),
  customerPointOfSaleService: new CustomerService(),
  orderPointOfSaleService: new OrderService(),
  invoicePointOfSaleService: new InvoiceService(),
  employeePointOfSaleService: new EmployeeService(),
  addressPointOfSaleService: new AddressService(),
  eddressPointOfSaleService: new EddressService(),

};
