import inventoryParser from "./parsers/inventory/parse-to-inventory";
import { data as _fakeInventory } from "./dummy-data/_fake.inventory";
// import productsParser from "./parsers/parse-to-products";

const ENDPOINT = "stocks";

const getAllByStoreId =
  ({ client, authHeader }) =>
  async ({ storeId, accessToken,storeCountry }) => {
    // return new Promise((resolve, reject) => {
    //   const parsedData = inventoryParser.parseToInventory(_fakeInventory)
    //   resolve(parsedData);
    //   reject(new Error("Error"));
    // });

    const response = await client.get(`/stores/${storeCountry}/${storeId}/${ENDPOINT}`, {
      headers: authHeader(accessToken),
    });

    const stocks = inventoryParser.parseToInventory(response.data);

    return stocks;
  };

export default (client, authHeader) =>
  class ProductService {
    getAllByStoreId = getAllByStoreId({ client, authHeader });
  };
