import pointOfSaleServices from "./point-of-sale";

export default {
  storePointOfSaleService: pointOfSaleServices.storePointOfSaleService,
  productPointOfSaleService: pointOfSaleServices.productPointOfSaleService,
  inventoryPointOfSaleService: pointOfSaleServices.inventoryPointOfSaleService,
  customerPointOfSaleService: pointOfSaleServices.customerPointOfSaleService,
  orderPointOfSaleService: pointOfSaleServices.orderPointOfSaleService,
  invoicePointOfSaleService: pointOfSaleServices.invoicePointOfSaleService,
  employeePointOfSaleService: pointOfSaleServices.employeePointOfSaleService,
  addressPointOfSaleService: pointOfSaleServices.addressPointOfSaleService,
  eddressPointOfSaleService: pointOfSaleServices.eddressPointOfSaleService
};
