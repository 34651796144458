import parser from "./parsers/invoice/parse-to-point-of-sale-invoice";

const ENDPOINT = "invoice";

const create =
  ({ client, authHeader }) =>
  async ({
    order,
    customer,
    selectedCustomer,
    selectedEmployee,
    paymentMethod,
    accessToken,
    country,
  }) => {

    const parsedData = parser.parseToPointOfSaleInvoice({
      customer,
      selectedCustomer,
      selectedEmployee,
      paymentMethod,
      country,
    });

    const response = await client.post(
      `/orders/${order.uid}/${ENDPOINT}`,
      parsedData,
      {
        headers: authHeader(accessToken),
      }
    );

    return response.data;
  };

export default (client, authHeader) =>
  class CustomerService {
    create = create({ client, authHeader });

    // get = get({ client, authHeader });
    // create = create({ client, authHeader });
    // update = update({ client, authHeader });
    // remove = remove({ client, authHeader });
    // removeAll = removeAll({ client, authHeader });
    // unremove = unremove({ client, authHeader });
  };
