import customerParser from "./parsers/customer/parse-to-customer";
import { data as _fakeCustomers } from "./dummy-data/_fake.customers";
// import customersParser from "./parsers/parse-to-customers";

const ENDPOINT = "customers";

const getAll =
  ({ client, authHeader }) =>
  async ({searchString, accessToken,storeCountry}) => {
    // return new Promise((resolve, reject) => {
    //   const parsedData = _fakeCustomers.map(customerParser.parseToCustomer);
    //   const foundCustomers = parsedData.filter(
    //     (customer) =>
    //       customer.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       customer.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       customer.nit.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //       customer.socialReason
    //         .toLowerCase()
    //         .includes(searchQuery.toLowerCase())
    //   );
    //   resolve(foundCustomers);
    //   reject(new Error("Error"));
    // });

    const response = await client.get(
      `/${ENDPOINT}/${storeCountry}?searchQuery=${searchString || ""}`,
      {
        headers: authHeader(accessToken),
      }
    );

    const customers = response.data.map(customerParser.parseToCustomer);

    return customers;
  };

const getAllByStoreId =
  ({ client, authHeader }) =>
  async (storeId) => {
    return new Promise((resolve, reject) => {
      const parsedData = _fakeCustomers.map(customerParser.parseToCustomer);
      resolve(parsedData);
      reject(new Error("Error"));
    });
    // const sort = !!sortOrder.name
    //   ? `&sort=${sortOrder.direction === "asc" ? "" : "-"}${sortOrder.name}`
    //   : "";

    // const response = await client.get(
    //   `/${ENDPOINT}?limit=${limit || ""}&page=${page || ""}${sort}`,
    //   {
    //     headers: authHeader(),
    //   }
    // );

    // const customers = customerParser.parseToCustomer(response.data);

    // return customers;
  };

// const get = ({ client, authHeader }) => async (id) => {
//   const response = await client.get(`/${ENDPOINT}/${id}`, {
//     headers: authHeader(),
//   });

//   const customer = customerParser.parseToCustomer(response.data);

//   return customer;
// };

// const create = ({ client, authHeader }) => (data) => {
//   return client.post(`/${ENDPOINT}`, data, { headers: authHeader() });
// };

// const update = ({ client, authHeader }) => (id, data) => {
//   return client.put(`/${ENDPOINT}/${id}`, data, { headers: authHeader() });
// };

// const remove = ({ client, authHeader }) => (id) => {
//   return client.delete(`/${ENDPOINT}/${id}`, { headers: authHeader() });
// };

// const removeAll = ({ client, authHeader }) => () => {
//   return client.delete(`/${ENDPOINT}`, { headers: authHeader() });
// };

// const unremove = ({ client, authHeader }) => (id) => {
//   return client.put(`/${ENDPOINT}/${id}/undelete`, null, {
//     headers: authHeader(),
//   });
// };

export default (client, authHeader) =>
  class CustomerService {
    getAll = getAll({ client, authHeader });
    getAllByStoreId = getAllByStoreId({ client, authHeader });

    // get = get({ client, authHeader });
    // create = create({ client, authHeader });
    // update = update({ client, authHeader });
    // remove = remove({ client, authHeader });
    // removeAll = removeAll({ client, authHeader });
    // unremove = unremove({ client, authHeader });
  };
